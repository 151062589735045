import React from "react";

const LocationsGrid = () => {
    const ourLocations = [
        {
            country: "Australia",
            location: "Sedin Technologies Pty Ltd.",
            phone: [{ phone_num_text: "+61 385 648 541", phone_num: "+61 385 648 541" }],
            address: "Suite 206, 793 Burke Rd, Camberwell, <br>  VIC, 3124 Australia",
            country: "Australia",
        },
        {
            country: "Canada",
            location: "Sedin Technologies Corp.",
            phone: [{ phone_num_text: "+1 (506) 230-1305", phone_num: "+1-5062301305" }],
            address:
                "Planet Hatch, 50 Crowther Lane, Suite 140,<br> Knowledge Park,Fredericton, NB, E3C 0J1, Canada.",
            country: "Canada",
        },
        {
            country: "India",
            location: "Sedin Technologies Private Ltd.",
            phone: [{ phone_num_text: "+91 44-455-012-95", phone_num: "+91 44-455-012-95" }],
            address: "# A1/1, 49th Street, 7th Avenue,<br> Ashok Nagar, Chennai, TN, India, 600083",
            country: "India(Chennai)",
        },
        {
            country: "India",
            location: "Sedin Technologies Private Ltd.",
            phone: [{ phone_num_text: "+91 44-455-012-95", phone_num: "+91 44-455-012-95" }],
            address: "Plot No.490, 46th Street, Ashok Nagar,<br>Chennai, TN, India, 600083",
            country: "India(Chennai)",
        },
        {
            country: "India",
            location: "Sedin Technologies Private Ltd.",
            phone: [{ phone_num_text: "+91 44-455-012-95", phone_num: "+91 44-455-012-95" }],
            address: "9th A Main road, 1st Stage Indira Nagar,<br> Bangalore, KA, India, 560038",
            country: "India(Bangalore)",
        },
        {
            country: "Singapore",
            location: "Sedin Technologies Pte. Ltd.",
            phone: [{ phone_num_text: "", phone_num: "" }],
            address: "30 Cecil Street, #19-08 Prudential Tower, <br> Singapore, 049712",
            country: "Singapore",
        },
        {
            country: "USA",
            location: "Sedin Technologies Inc.",
            phone: [{ phone_num_text: "+1 4159033658", phone_num: "+1 4159033658" }],
            address: "440 N. Wolfe Rd, Sunnyvale, CA 94085, USA",
            country: "USA",
        },
        {
            country: "UAE",
            location: "Sedin Technologies ME LLC-FZ",
            phone: [{ phone_num_text: "", phone_num: "" }],
            address: "Meydan Grandstand, 6th Floor, Meydan Road, Nad Al Sheba <br/> Dubai, UAE",
            country: "UAE",
        },
        {
            country: "India(Pune)",
            location: "Sedin Technologies Private Ltd.",
            phone: [{ phone_num_text: "+91 44-455-012-95", phone_num: "+91 44-455-012-95" }],
            address:
                "1st and 2nd Floor Sky Loft, Creaticity Mall, Survey no 190/192, Opposite Golf Course, Off Airport Rd, <br> Shastrinagar,  Yerawada, Pune, Maharashtra  41100",
        },
    ];

    return (
        <div className="locations-grid ">
            {ourLocations
                .sort((a, b) => a.country.localeCompare(b.country))
                .map((l, i) => (
                    <div className="location-item" key={`location_${i}`}>
                        <div className="country">{l.country}</div>
                        <div className="location">{l.location}</div>
                        <div
                            className="address"
                            dangerouslySetInnerHTML={{ __html: l.address }}
                        ></div>
                        {l.phone?.map((p, i) => (
                            <div key={`contact__${i}`}>
                                <a className="contact" href={`tel:${p.phone_num}`}>
                                    {p.phone_num_text}
                                    <span className="tooltiptext">Click to call Sedin</span>
                                </a>
                            </div>
                        ))}
                        <div className="email ">
                            <a href="mailto:sales@sedintechnologies.com" className="sdn-link ">
                                sales@sedintechnologies.com
                            </a>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default LocationsGrid;
